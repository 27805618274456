/* global */

/* colors */
$main-color: #3f4248;
$top-nav-text-color: #A0ABD1;
$home-small-text-color: #D9D9D9;
$swap-box-background-color: #181B21;


$color1: #1985A1;
$color2: #4C5C68;
$color3: #46494C;
$color4: #C5C3C6;
$color5: #DCDCDD;




/* transitions */

/* box-shadows */