@import '../../../styles/variable';

#header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
  max-width: 1140px;

  .header-logo {
    height: 32px;
    object-fit: contain;
    margin-right: 20px;
    cursor: pointer;
  }

  .title {
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 22px;
    margin-right: 16px;
  }

  nav {
    display: flex;
    align-items: center;
    width: 100%;

    a {
      margin: 0 20px;
      font-size: 14px;
      font-weight: 500;
      color: $top-nav-text-color;

      &.active {
        color: white;
      }
    }

    .active {
      color: #ffffff;
    }
  }


  input {
    font-size: 1em;
    padding: 6px 12px;
    background-color: $color2;
    border-radius: 6px;
    color: $color5;
  }

  .not-connected-button {
    padding: 12px 24px;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    background: linear-gradient(265.56deg, #246CF9 -0.27%, #002C81 -0.26%, #0047D0 98.59%);
    font-weight: 400;
    white-space: nowrap;
  }

  .connected-button {
    padding: 12px 24px;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    border: solid 2px #0047D0;
    background-color: #181B24;
    white-space: nowrap;
  }
}


#m-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px;

  .header-logo {
    height: 32px;
    object-fit: contain;
  }

  .title {
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 22px;
    margin-right: 16px;
  }

  .menu-button {
    img {
      width: 24px;
      height: 24px;
    }
  }

  nav {
    display: flex;
    align-items: center;
    width: 100%;

    a {
      margin: 0 20px;
      font-size: 14px;
      font-weight: 500;
      color: $top-nav-text-color;


    }


  }


  input {
    font-size: 1em;
    padding: 6px 12px;
    background-color: $color2;
    border-radius: 6px;
    color: $color5;
  }

  .not-connected-button {
    padding: 12px 24px;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    background-image: linear-gradient(120deg, #1E68F6 0%, #0047D0 100%);
    white-space: nowrap;
  }

  .connected-button {
    padding: 12px 24px;
    border-radius: 30px;
    font-size: 14px;
    color: white;
    border: solid 2px #0047D0;
    background-color: #181B24;
    white-space: nowrap;
  }
}


.menu {
  width: 100%;
  height: 100vh;
  padding: 0 24px;

  a {
    margin: 40px 0;
    font-size: 40px;
    font-weight: 700;
    color: white;
  }
}