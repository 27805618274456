@import '../styles/variable';

#pool {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .pool-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $swap-box-background-color;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 15px;

    @media only screen and (min-width: 992px) {
      width: 440px;
      height: 800px;
    }

    &-title {
      font-weight: 700;
      color: $home-small-text-color;
      font-size: 50px;
    }
  }
}