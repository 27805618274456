@import '../styles/variable';
@import 'src/view/styles/mixins';

#swap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .swap-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: $swap-box-background-color;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 15px;

    @media only screen and (min-width: 992px) {
      width: 440px;
      height: 800px;
    }

    &-title {
      font-weight: 700;
      color: $home-small-text-color;
      font-size: 50px;
    }
  }

  .exchange-button {
    position: absolute;
    bottom: -24px;
    left: calc(50% - 17px);
    z-index: 10;


    img {
      width: 34px;
      height: 34px;
    }
  }

  .swap-inner-box {
    position: relative;
    width: 100%;
    background-color: #20232D;
    padding: 20px;
    border-radius: 14px;
    border: solid 1px #34384C;
    box-sizing: border-box;

    @media only screen and (min-width: 992px) {
      width: 400px;
    }

    input {
      width: 100%;
      padding-right: 10px;
      border: none;
      background-color: inherit;
      color: #ffffff;
      font-size: 27px;
      outline: none;
    }

    .dollar {
      margin-right: 20px;
      color: #77869D;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      @include x-scroll-without-scrollbar();
    }

    .balance {
      color: #1D74FF;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
    }

    .token-selector {
      display: flex;
      background-color: #181B24;
      border-radius: 20px;
      padding: 6px;
      color: white;
      box-sizing: border-box;

      img {
        width: 20px;
        height: 20px;
      }

      .unit {
        margin: 0 10px;
      }
    }

    .label {
      color: #96A1BB;
      font-size: 13px;
      white-space: nowrap;
    }

    .value {
      width: 100%;
      color: #D9D9D9;
      font-size: 13px;
      // overflow: hidden;
      text-align: right;
      margin-left: 20px;
      white-space: nowrap;
      @include x-scroll-without-scrollbar();
    }

    .line {
      height: 1px;
      width: 100%;
      margin: 12px 0;
      background-color: #34384C;
    }

  }

  .alert-icon {
    width: 20px;
    height: 20px;
  }

  .alert-text {
    margin-left: 10px;
    color: #D9D9D9
  }

  .connect-button {
    width: 100%;
    margin-top: 30px;
    border-radius: 100px;
    padding: 20px;
    color: white;
    font-size: 18px;
    //background-image: linear-gradient(120deg, #1E68F6 0%, #0047D0 100%)
    background: linear-gradient(265.56deg, #246CF9 -0.27%, #002C81 -0.26%, #0047D0 98.59%);
    font-weight: 400;
  }

  .swap-button {
    width: 100%;
    margin-top: 30px;
    border-radius: 100px;
    padding: 20px;
    color: white;
    font-size: 18px;
    //background-image: linear-gradient(120deg, #1E68F6 0%, #0047D0 100%);
    background: linear-gradient(265.56deg, #246CF9 -0.27%, #002C81 -0.26%, #0047D0 98.59%);
    font-weight: 400;

    &:disabled {
      background: linear-gradient(265.56deg, #787B82 -0.27%, #20252E -0.26%, #343840 98.59%);
    }
  }
}