@import '../../../styles/variable';

.pool-main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #181B21;
  color: #D9D9D9;
  overflow: auto;

  &-title {
    width: 100%;
    font-size: 50px;
    font-weight: 700;
    text-align: left;
  }

  &-token-box {
    position: relative;
    width: 100%;
    background-color: #20232D;
    padding: 20px;
    border-radius: 14px;
    border: solid 1px #34384C;
    box-sizing: border-box;

    @media only screen and (min-width: 992px) {
      //width: 400px;
    }

    .token-selector {
      display: flex;
      background-color: #181B24;
      border-radius: 20px;
      padding: 6px;
      color: white;
      box-sizing: border-box;

      img {
        width: 20px;
        height: 20px;
      }

      .unit {
        margin: 0 10px;
      }
    }
  }

  .liquidity-row {
    margin-top: 8px;
    border-radius: 20px;
    background-color: black;
    padding-right: 20px;

    @media (max-width: 992px) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }

    &-label {
      display: flex;
      background-color: #181B24;
      border-radius: 20px;
      height: 32px;
      padding: 6px 12px;
      color: white;
      box-sizing: border-box;
      font-size: 16px;
      font-weight: normal;
    }
  }


  .pool-list {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    width: 100%;
    height: 100%;
  }

  .share {
    margin-left: 6px;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    background-color: #181B24;
  }

  .liquidity-label {
    margin-top: 12px;
    color: #77869D;
    font-size: 12px;
  }

  .pool-token-image {
    width: 18px;
    height: 18px;
  }

  .pool-inner-box {
    position: relative;
    width: 100%;
    background-color: #20232D;
    padding: 20px;
    border-radius: 14px;
    border: solid 1px #34384C;
    box-sizing: border-box;

    @media (max-width: 992px) {
      padding: 14px 8px;
    }
  }

  &-action-button {
    background: linear-gradient(265.56deg, #246CF9 -0.27%, #002C81 -0.26%, #0047D0 98.59%);
    font-weight: 400;
    margin-top: 20px;
    width: 175px;
    height: 40px;
    //padding: 6px 12px;
    border-radius: 20px;
    color: white;
    &.reverse{
      background: linear-gradient(268.41deg, #AFBEDB 0.74%, #BDC5D5 99.47%);
      color: #181B24;
    }
    @media (max-width: 992px) {
      width: 100%;
      padding: 6px 12px;
    }
  }

  &-refresh-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10px;
    width: 48px;
    height: 48px;
    border-radius: 26px;
    background-color: #20232D;
    border: solid 1px #34384C;

    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
}