footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 1140px;
  width: 100%;
  height: 100px;
  bottom: 0;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  @media only screen and (max-width: 992px) {
    justify-content: center;
  }

  .footer-wrapper {
    display: flex;

    .footer-link {
      &:not(:last-child) {
        margin-right: 48px;
      }

      font-size: 14px;
      font-weight: 500;
      color: #A0ABD1;
      cursor: pointer;
    }
  }

}