@import '../styles/variable';

#faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .faq-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $swap-box-background-color;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 15px;
    color: white;

    @media only screen and (min-width: 992px) {
      width: 440px;
      height: 800px;
    }

    &-title {
      font-weight: 700;
      color: $home-small-text-color;
      font-size: 50px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
      min-width: 200px !important;
      font-size: 16px;
    }

    input,
    textarea {
      position: relative;
      margin-top: 10px;
      width: 100%;
      background-color: #20232D;
      padding: 20px;
      border-radius: 14px;
      border: solid 1px #34384C;
      box-sizing: border-box;
      color: white;
      font-size: 16px;

      @media only screen and (min-width: 992px) {
        width: 400px;
      }
    }

    textarea {
      resize: none;
      display: flex;
      height: 200px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
  }

  .button {
    width: 100%;
    border-radius: 100px;
    padding: 20px;
    color: white;
    font-size: 18px;
    color: white;
    background: linear-gradient(265.56deg, #246CF9 -0.27%, #002C81 -0.26%, #0047D0 98.59%);
    font-weight: 400;

    &:disabled {
      background-image: linear-gradient(120deg, #787B82 0%, #343840 100%);
    }
  }

  .faq-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 30px;
    box-sizing: border-box;

    .container {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 1440px;
      padding: 0 170px;

      @media only screen and (max-width: 992px) {
        padding: 0 30px;
        //width: 440px;
      }
    }

    .faq-footer-wrapper {
      display: flex;

      .faq-footer-link {
        &:not(:last-child) {
          margin-right: 48px;
        }

        font-size: 14px;
        font-weight: 500;
        color: #A0ABD1;
        cursor: pointer;
      }
    }

  }

}