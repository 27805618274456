@import 'src/view/styles/variable';

.box-back-button {
  width: 24px;
  height: 24px;

  img {
    width: 24px;
    height: 24px;
  }
}