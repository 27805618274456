@import '../../../styles/variable';
@import 'src/view/styles/mixins';

.add-liquidity {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #181B21;
  color: #D9D9D9;

  &-title {
    font-size: 50px;
    text-align: left;
  }

  &-sub-title {
    font-size: 20px;
    text-align: center;
  }

  &-token-box {
    position: relative;
    width: 100%;
    background-color: #20232D;
    padding: 20px;
    border-radius: 14px;
    border: solid 1px #34384C;
    box-sizing: border-box;

    @media only screen and (min-width: 992px) {
      //width: 400px;
    }

    .token-selector {
      display: flex;
      background-color: #181B24;
      border-radius: 20px;
      padding: 6px;
      color: white;
      box-sizing: border-box;

      img {
        width: 20px;
        height: 20px;
      }

      .unit {
        margin: 0 10px;
      }
    }

    input {
      width: 100%;
      border: none;
      background-color: inherit;
      color: white;
      font-size: 27px;
      outline: none;
    }

    &-bottom-row {
      margin-top: 10px;
      font-size: 12px;
      color: #77869D;

      .dollar {
        white-space: nowrap;
        color: #1D74FF;
        margin-right: 20px;
        @include x-scroll-without-scrollbar();
      }

      .balance {
        white-space: nowrap;
        color: #1D74FF;
      }
    }

    .line {
      height: 1px;
      width: 100%;
      margin: 12px 0;
      background-color: #34384C;
    }

    &-label {
      color: #96A1BB;
      font-size: 13px;
    }

    &-value {
      color: #D9D9D9;
      font-size: 13px;
    }
  }

  &-button {
    margin-top: 30px;
    width: 100%;
    padding: 20px;
    background: linear-gradient(265.56deg, #246CF9 -0.27%, #002C81 -0.26%, #0047D0 98.59%);
    font-weight: 400;
    color: white;
    border-radius: 50px;
    font-size: 1.2em;

    &:disabled {
      background: linear-gradient(265.56deg, #787B82 -0.27%, #20252E -0.26%, #343840 98.59%);
    }
  }
}