@import 'src/view/styles/variable';

@mixin font-face-OTF($name, $file, $weight: noraml, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    font-weight: $weight;
    font-style: $style;
    src: url("/assets/fonts/#{$file}.otf") format("truetype");
  }
}

@mixin font-face-TTF($name, $file, $weight: noraml, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    font-weight: $weight;
    font-style: $style;
    src: url("/assets/fonts/#{$file}.ttf") format("truetype");
  }
}

@include font-face-TTF("Roboto", "Roboto-Bold", bold);
@include font-face-TTF("Roboto", "Roboto-Regular", normal);
@include font-face-TTF("Roboto", "Roboto-Medium", 500);
@include font-face-TTF("Roboto", "Roboto-Black", 800);
@include font-face-TTF("StreetSemiBold", "Stresbld", 500);

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {

  //background-image: linear-gradient(225deg, #050810 5%, #23262E 20%, #050810 35%)
  background-color: #000000;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
}

a {
  width: fit-content;
  text-decoration: none;
  display: flex;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
  overflow: auto;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(174, 174, 174, 0.159);
  transition: 0.15s linear;

  &:hover {
    background-color: rgba(174, 174, 174, 0.522);
  }
}

iframe {
  display: none;
}

.modal-overlay-style {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}