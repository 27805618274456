@import "src/view/styles/variable";

.main-button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: $main-color;
  color: white;
  font-size: 1em;
  
  &:hover{
    cursor: pointer;
  }
  &:focus{
    outline: none;
  }
}
