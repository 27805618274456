@import 'src/view/styles/variable';

.token-item {
  display: flex;
  background-color: #181B24;
  border-radius: 20px;
  padding: 6px;
  color: white;
  box-sizing: border-box;

  img {
    width: 20px;
    height: 20px;
  }

  &-unit {
    margin: 0 10px;
    font-size: 16;
    font-weight: normal;
  }
}