@import '../../../styles/variable';
@import 'src/view/styles/mixins';

.remove-liquidity {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #181B21;
  color: #D9D9D9;

  &-title {
    text-align: center;
  }

  .percentage {
    button {
      padding: 8px;
      border-radius: 8px;
      background-color: #20232D;
      color: #1D74FF;
      font-size: 16px;
      font-weight: 500;
      border: none;
    }

    input[type="range"] {
      -webkit-appearance: none;
      margin: 20px 20px 20px 0;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: #34384C;
      // background-image: linear-gradient($color1, $color1);
      // background-size: 70% 100%;
      // background-repeat: no-repeat;
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: #1E68F6;
      cursor: ew-resize;
      // box-shadow: 0 0 2px 0 #555;
      transition: background .3s ease-in-out;
    }

    input[type=range]::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }

  &-receive-box {
    position: relative;
    width: 100%;
    background-color: #20232D;
    padding: 16px 20px;
    border-radius: 14px;
    border: solid 1px #34384C;
    box-sizing: border-box;

    @media only screen and (min-width: 992px) {
      width: 400px;
    }

    &-label {
      font-size: 12px;
      color: #77869D
    }
  }

  &-receive-amount {
    margin-left: 20px;
    text-align: right;
    font-size: 27px;
    font-weight: 500;
    color: #D9D9D9;
    @include x-scroll-without-scrollbar();
  }

  .token-selector {
    display: flex;
    background-color: #181B24;
    border-radius: 20px;
    padding: 6px;
    color: white;
    box-sizing: border-box;

    img {
      width: 20px;
      height: 20px;
    }

    .unit {
      margin: 0 10px;
    }
  }

  &-button {
    margin-top: 30px;
    width: 100%;
    padding: 20px;
    background: linear-gradient(265.56deg, #246CF9 -0.27%, #002C81 -0.26%, #0047D0 98.59%);
    font-weight: 400;
    color: white;
    border-radius: 50px;
    font-size: 1.2em;

    &:disabled {
      background: linear-gradient(265.56deg, #787B82 -0.27%, #20252E -0.26%, #343840 98.59%);
    }
  }
}