@import '../../../styles/variable';
@import 'src/view/styles/mixins';


.history {
  &-title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  &-close-button {
    position: absolute;
    top: 19px;
    right: 20px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &-back-button {
    position: absolute;
    top: 22px;
    left: 20px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  &-tab-button {
    color: #77869D;
    font-size: 14px;
    margin-right: 2px;
    padding: 8px;

    &:disabled {
      background-color: #20232D;
      color: #1D74FF;
      padding: 8px;
      border-radius: 20px;
    }
  }

  &-address {
    margin-top: 32px;
    font-size: 11px;
    font-weight: 500;
  }

  &-blue-button {
    color: #3F7FFF;
    font-size: 11px;
    font-weight: 500;

    img {
      width: 12px;
      height: 12px;
      margin-left: 4px;
    }
  }

  &-box {
    width: 100%;
    padding: 10px;
    background-color: #20232D;
    border: solid 1px #34384C;
    border-radius: 14px;
    font-size: 12px;
    font-weight: 600;

    @media only screen and (min-width: 992px) {
      width: 280px;
    }

    &-label {
      margin-top: 8px;
      font-size: 11px;
      font-weight: 600;
      white-space: nowrap;
      @include x-scroll-without-scrollbar();
    }
  }

  &-token-image {
    width: 18px;
    height: 18px;
  }

  &-token-balance {
    background-color: #181B24;
    border-radius: 40px;
    margin-top: 8px;
    padding: 8px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    &-unit {
      width: 100%;
      text-align: left;
    }
  }

  &-info {
    margin-top: 8px;

    &-label {
      color: #77869D;
    }
  }

  &-scroll-view {
    height: 600px;
    overflow: auto;

    @media only screen and (min-width: 992px) {
      height: 323px;
    }
  }


  .history-button {
    padding-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: white;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .line {
    height: 1px;
    width: 100%;
    margin: 12px 0;
    background-color: #34384C;
  }
}