@import '../../../styles/variable';
@import 'src/view/styles/mixins';

.profile {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #181B21;
  border-radius: 10px;
  padding: 20px;
  color: white;
  margin-bottom: 1000px;


  @media only screen and (min-width: 992px) {
    position: absolute;
    top: 60px;
    right: 0;
    width: 325px;
    z-index: 20;
  }

  &-title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }

  &-close-button {
    position: absolute;
    top: 19px;
    right: 20px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &-address {
    margin-top: 32px;
    font-size: 11px;
    font-weight: 500;
  }

  &-blue-button {
    color: #3F7FFF;
    font-size: 11px;
    font-weight: 500;

    img {
      width: 12px;
      height: 12px;
      margin-left: 4px;
    }
  }

  &-box {
    padding: 10px;
    background-color: #20232D;
    border: solid 1px #34384C;
    border-radius: 14px;

    &-label {
      padding: 10px 8px;
      font-size: 11px;
      font-weight: 500;
    }
  }

  &-token-balance {
    background-color: #181B24;
    border-radius: 40px;
    margin-top: 8px;
    padding: 8px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    &-unit {
      margin-right: 20px;
    }

    &-amount {
      width: 100%;
      text-align: right;
      @include x-scroll-without-scrollbar();
    }
  }

  .history-button {
    padding-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: white;

    img {
      width: 16px;
      height: 16px;
    }
  }
}