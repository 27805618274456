@import 'src/view/styles/variable';

@keyframes move {
  from {
    left: 1500px;
  }

  to {
    left: -6700px;
  }
}

@keyframes mmove {
  from {
    left: 1000px;
  }

  to {
    left: -5200px;
  }
}


#home {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 80px);
  padding: 30px;
  overflow: hidden;

  .container {
    position: relative;
    display: flex;
    max-width: 1440px;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1500px) {
      width: 100%;
      max-width: 932px;
    }

  }

  .inner-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin-top: 120px;
    padding: 30px;
    padding-bottom: 100px;

    @media only screen and (max-width: 1500px) {
      flex-direction: column;
      align-items: center;
    }

    .data-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 578px;
      height: 100%;

      @media only screen and (max-width: 1500px) {
        flex-direction: row;
        width: 100%;
        margin-top: 180px;
      }
    }
  }

  .move-text-wrapper {
    margin-top: 40px;

    .move-row-text {
      font-size: 48px;
      color: white;
      padding: 10px 20px;
      background-color: #1D74FF;
      border-radius: 12px;
    }
  }

  .big-text1 {
    font-weight: 800;
    font-size: 120px;
    white-space: break-spaces;
    color: white;
  }

  .main-banner {
    width: 900px;
    object-fit: contain;

    @media only screen and (max-width: 1500px) {
      width: 750px;
    }
  }

  .liquidity {
    &-box {
      color: white;

      &-unit {
        margin-top: 10px;
        font-size: 22px;
        font-weight: 400;
      }

      &-amount {
        font-size: 60px;
        font-weight: 700;
        white-space: nowrap;
      }
    }
  }


  .background-text {
    position: absolute;
    height: 260px;
    opacity: 0.7;
    top: 300px;
    object-fit: contain;
    animation-duration: 16s;
    animation-name: move;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;
  }

  .background-image {
    position: absolute;
    min-width: 1000px;
    width: 100vw;
    top: 680px;
    object-fit: contain;
  }

  .background-video {
    position: fixed;
    bottom: auto;
    top: 540px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 2000px;

    @media only screen and (max-width: 1500px) {
      top: 600px;
      width: 1600px;
    }

    object-fit: contain;
  }
}


#m-home {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 30px;


  .header-logo {
    height: 40px;
    object-fit: contain;
  }

  .inner-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 70px;

    @media (min-width: 768px) {
      margin-top: 90px;
    }

    @media (min-width: 992px) {}
  }

  .big-text1 {
    font-weight: 800;
    font-size: 120px;
    white-space: break-spaces;
    color: white;
  }

  .main-banner {
    width: 100%;
    // height: 238px;
  }

  .liquidity {
    position: absolute;
    top: -40px;

    @media (min-width: 768px) {
      margin-top: 80px;
    }

    @media (min-width: 992px) {}

    &-box {
      color: white;

      &-unit {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 500;
      }

      &-amount {
        text-align: center;
        font-weight: 900;
        white-space: nowrap;
        font-size: 40px;

        @media (min-width: 768px) {
          font-size: 50px;

        }

        @media (min-width: 992px) {
          font-size: 60px;
        }
      }
    }
  }


  .background-text {
    position: absolute;
    top: 26%;
    height: 240px;
    opacity: 0.7;
    object-fit: contain;
    animation-duration: 30s;
    animation-name: mmove;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-timing-function: linear;
  }

  .background-video {
    position: absolute;
    top: 400px;
    width: 100vw;
    object-fit: contain;
  }

  .mobile-footer {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    bottom: 50px;
    color: #A0ABD1;

    .mobile-footer-container {
      display: flex;
      flex-direction: row;

      .mobile-footer-link {
        &:not(:last-child) {
          margin-right: 20px;
        }

        font-size: 14px;
        font-weight: 500;
        color: #A0ABD1;
        cursor: pointer;
      }
    }
  }
}