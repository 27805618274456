#video-background {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  padding: 0 20px 20px;

  @media only screen and (min-width: 1440px) {
    flex-direction: row;
    min-height: 800px;
    padding: 0 170px;
  }

  .video-wrapper {
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 1440px) {
      flex: 1;
    }

    .background-video {
      width: 100%;

      @media (max-width: 1440px) {
        position: fixed;
        width: 1200px;
        z-index: -1;
        bottom: -800px;
        left: 50%;
        margin: 0;
        transform: translateX(-50%);
      }
    }

    .text-loop-wrapper {
      z-index: 5;
      position: absolute;
      top: calc(50% - 28px);

      .loop-row-wrapper {
        width: 760px;
        text-align: center;
        font-size: 60px;
        font-weight: bold;
        color: white;
      }

    }
  }
}